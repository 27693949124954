import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";


const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">UNIVERS SERVICES</h3>
                <div className="custom-content">
                  Univers services 221 est une société de services qui d’active dans les domaines suivants
                  <ul>
                    <li className="list-item">
                      Vente électroménagers et mobiliers de maison avec des facilités de paiement
                    </li>
                    <li className="list-item">Management business ( digitalization des taches et gestion comptable et
                      contrôle financier)
                    </li>
                    <li className="list-item">
                      Formation, Gestion et placement personnel bureau et maison
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-12 pt-2">
                <div className="custom-content">
                  <h5 className="pb-2 title-item">
                    <strong>Distribution de produits électroménagers</strong>
                  </h5>
                  <div>
                    Nous vous accompagnons dans l’équipement de vos maison par acquisition de matériels électroménagers
                    par paiements échelonnés ou tontines <br />
                    Des produits de grandes marques avec garantie <br />
                    Nous travaillons avec des distributeurs de grandes marques <br />
                    Équipez vos maisons et payez en 6 mois ou en tontine
                    <ol>
                      <li className="list-item">
                        Sélectionnez vos articles sur notre catalogue Wb 707858544
                      </li>
                      <li className="list-item">
                        Soumettez les suretés pour les travailleurs, pour les autres signez l’engagement
                      </li>
                      <li className="list-item">
                        Dans un délais de 24 h un agent vous contactera pour valider votre commande et établir la convention
                      </li>
                      <li className="list-item">
                        Vous serez orienté vers notre Distributeur disposant de l’article avec votre pièce d’identité et votre code Achat
                      </li>
                      <li className="list-item">
                        Le distributeur vous remettra la facture Bl avec la garantie lié à votre article.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '5rem 0' }}></div>

      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
